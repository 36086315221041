@tailwind base;
@tailwind components;
@tailwind utilities;





::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.numeric {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}